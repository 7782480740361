import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { v4 as uuidv4 } from 'uuid'
import HeaderSEO from '../components/partials/HeaderSEO'
import Layout from '../components/Layout'
import AboveFooterSection from '../components/AboveFooterSection'

import { StaticImage } from 'gatsby-plugin-image'

const Videos = () => {
    const data = useStaticQuery(graphql`
        query VideosQuery {
            allContentfulVideo(sort: { fields: date, order: DESC }) {
                edges {
                    node {
                        date
                        title
                        slug
                        videoTypeColor
                        videoType
                        videoId
                    }
                }
            }
            allContentfulPages(filter: { pageReference: { pageIdentifier: { eq: "Videos" } } }) {
                edges {
                    node {
                        title {
                            title
                        }
                        keywords {
                            keywords
                        }
                        description {
                            description
                        }
                        oGimage {
                            fluid(quality: 100) {
                                src
                            }
                        }
                    }
                }
            }
        }
    `)

    const pageSEO = data.allContentfulPages.edges[0].node
    const videosList = data.allContentfulVideo.edges

    // Format used to render the date
    const dateOptions = {
        year: 'numeric',
        month: 'short',
    } as const

    return (
        <Layout>
            <HeaderSEO pageSEO={pageSEO} />
            <StaticImage
                className="home-fixed-top"
                src="../resources/images/homepage/hero_lens.svg"
                placeholder="blurred"
                alt="Hero Lenses"
            />
            <section className="pt-5 mt-5 pb-5 ">
                <div className="container-1">
                    <div className="d-flex flex-wrap mt-3">
                        <div className="col-md-6 pt-0">
                            <p className="title breadcrumb-text pt-4">
                                <a href="/resources/" className="title_text">
                                    RESOURCES
                                </a>
                            </p>
                            <h1>Videos</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section id="summary" className="pt-3 pb-5">
                <div className="container-1">
                    <div className="d-flex flex-wrap">
                        {videosList.map(({ node }) => {
                            // Grabbing the date from Contentful Node
                            let tempDate = node.date
                            // Create new Date object from the tempDate
                            let dateToDisplay = new Date(tempDate)
                            // Setting a Video card color - as specified in Contentful
                            let cardColor = `ds-card-label-${node.videoTypeColor}`
                            // GettingVideo
                            let videoImage = `https://img.youtube.com/vi/${node.videoId}/mqdefault.jpg`
                            return (
                                <div
                                    className="col-sm-12 col-md-4 mb-4 scale-on-hover"
                                    key={uuidv4()}
                                >
                                    <div className="ds-card mb-2">
                                        <a href={`/videos/${node.slug}/`}>
                                            <img
                                                className="w-100"
                                                src={videoImage}
                                                alt={node.title}
                                            />
                                            <h2 className="ds-card-title videos-h2">
                                                {node.title}
                                            </h2>
                                            <span className={cardColor}>{node.videoType}</span>
                                            <span
                                                className="f-10"
                                                style={{ position: 'absolute', right: '20px' }}
                                            >
                                                {dateToDisplay.toLocaleDateString(
                                                    'en-US',
                                                    dateOptions
                                                )}
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            )
                        })}
                        {/* END OF FOR ABOVE ^ */}
                    </div>
                </div>
            </section>

            <AboveFooterSection
                text={'Ready to get started with Lenses?'}
                ctaText={'Try now for free'}
                ctaLink="/start/"
            />
        </Layout>
    )
}

export default Videos
