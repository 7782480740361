import React from 'react'
import PropTypes from 'prop-types'

const aboveFooter = ({ text, ctaText, ctaLink }) => {
    return (
        <section className='mobile-margin-bottom mobile-margin-top my-5'>
            <div className='container-1 bg-dark rounded-1 p-5'>
                <div className='d-flex flex-wrap'>
                    <div className='col-md-12 col-12 text-center roll-button'>
                        <p className='mb-5 paragraph-title text-white cera-pro f-28 fw-700'>
                            {text}
                        </p>
                        <a className='bg-white primary-text' href={ctaLink}>
                            <span data-hover={ctaText}> {ctaText}</span>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default aboveFooter

aboveFooter.propTypes = {
    text: PropTypes.string,
    ctaText: PropTypes.string,
    ctaLink: PropTypes.string,
}
